export const HEADER_NAV_LINKS = [
    {
        text: 'About',
        to: 'about'
    },
    {
        text: 'Projects',
        to: 'projects'
    },
    {
        text: 'Contact Me',
        to: 'contact'
    }
]

